import * as React from "react"
import { FaPlus, FaMinus } from "react-icons/fa"
import { MouseEventHandler } from "react"
import useHome, { Question } from "../../hooks/use-faq"

interface FaqProps {
  className?: string
  itemClassName?: string
}

interface FaqItemProps {
  className?: string
  faq: Question
  isOpen?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

function FaqItem({ className, faq, isOpen, onClick }: FaqItemProps) {
  return (
    <div className={["border-b-2 py-6"].join(" ")}>
      <button
        onClick={onClick}
        className={[
          "text-lg w-full flex justify-between items-center text-left",
          className,
        ].join(" ")}
      >
        <span>{faq.question}</span>
        <span>{isOpen ? <FaMinus size={15} /> : <FaPlus size={15} />}</span>
      </button>
      <div
        className={[
          "overflow-hidden",
          "transition-all",
          isOpen ? "max-h-96" : "max-h-0",
        ].join(" ")}
      >
        <div
          className={"prose pt-3"}
          dangerouslySetInnerHTML={{ __html: faq.answer }}
        />
      </div>
    </div>
  )
}

export default function Faq({ className, itemClassName }: FaqProps) {
  const [activeItem, setActiveItem] = React.useState<number>()
  const { faq } = useHome()

  return (
    <div
      className={["flex flex-col", "max-w-3xl", "w-full", className].join(" ")}
    >
      {faq.map((item, i) => (
        <FaqItem
          isOpen={i === activeItem}
          className={itemClassName}
          onClick={() => setActiveItem(i === activeItem ? undefined : i)}
          faq={item}
          key={`faqItem-${i}`}
        />
      ))}
    </div>
  )
}
