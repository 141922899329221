import { useStaticQuery, graphql } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"

export interface InstagramNode {
  node: {
    id: string
    caption: string
    localFile: ImageDataLike
  }
}

export default function useInstagramPosts(): Array<InstagramNode> {
  const { allInstaNode } = useStaticQuery(
    graphql`
      {
        allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 10) {
          edges {
            node {
              id
              caption
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    aspectRatio: 1
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  return allInstaNode.edges
}
