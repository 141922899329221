import * as React from "react"
import Container from "../container"
import Sticky from "react-stickynode"
import logo from "../../images/als-floorsanding.png"
import useSiteMetadata from "../../hooks/use-site-metadata"
import NavBar from "../nav-bar"
import NavBarMobile, { NavBarToggle } from "../nav-bar-mobile"

export default function HeaderBar() {
  const metadata = useSiteMetadata()
  const [showMenu, setShowMenu] = React.useState(false)
  return (
    <Sticky innerClass={"z-10"} enabled={true} top={0}>
      <div className={"bg-white relative z-10"}>
        <Container className={"p-6 items-center justify-between"}>
          <div className={"w-2/3 md:w-auto"}>
            <a href={metadata.siteUrl}>
              <img alt={"logo"} width={400} src={logo} />
            </a>
          </div>
          <NavBar />
          <NavBarToggle onClick={() => setShowMenu(!showMenu)} />
        </Container>
        <NavBarMobile showMenu={showMenu} />
      </div>
    </Sticky>
  )
}
