import { useStaticQuery, graphql } from "gatsby"

export interface SiteMetadata {
  title: string
  description: string
  siteUrl: string
  address: string
  contactFormAction: string
  socialLinks: {
    facebook: string
    instagram: string
    phone: string
    email: string
  }
}

export default function useSiteMetadata(): SiteMetadata {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            address
            siteUrl
            contactFormAction
            socialLinks {
              facebook
              instagram
              phone
              email
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}
