import * as React from "react"
import Container from "../container"
import Button from "../button"
import icon from "../../images/icon.svg"
import useSiteMetadata from "../../hooks/use-site-metadata"
import AnimateOnScroll from "../animate-on-scroll"

export default function HomeHero() {
  const metadata = useSiteMetadata()

  return (
    <Container
      className={"py-6 px-6 justify-between items-center flex-col md:flex-row"}
    >
      <div className={"max-w-2xl"}>
        <h2
          className={
            "text-4xl md:text-5xl tracking-wide font-heading text-green-600 uppercase mb-6"
          }
        >
          Providing floor sanding services in SMBI Islands and Redland bay
        </h2>
        <p className={"mb-6 text-lg"}>
          Leave your floor sanding polishing to the experts and get fantastic
          results. We can bring out the natural beauty of your existing flooring
          and decking.
        </p>
        <Button
          href={"#contact"}
          className={"mb-6"}
          theme={"primary"}
          hoverTheme={"secondary"}
        >
          Get a Quote
        </Button>
        <p className={"text-lg italic"}>
          Or call us on{" "}
          <a
            className={"hover:underline"}
            href={`tel:${metadata.socialLinks.phone.replace(/\s/g, "")}`}
          >
            {metadata.socialLinks.phone}
          </a>
        </p>
      </div>
      <div className={"hidden md:block"}>
        <AnimateOnScroll transition={"slide-right"}>
          <img src={icon} width={600} alt={"Al's Floorsanding cart"} />
        </AnimateOnScroll>
      </div>
    </Container>
  )
}
