import * as React from "react"
import Layout from "../components/layout"
import HeaderBar from "../components/header-bar"
import HomeHero from "../components/home-hero"
import HomeServices from "../components/home-services"
import HomeGallery from "../components/home-gallery"
import HomeFaq from "../components/home-faq"
import HomeContact from "../components/home-contact"

export default function IndexPage() {
  return (
    <Layout>
      <div className={"flex min-h-screen flex-col sm:justify-between"}>
        <div className={"flex-1 h-36 sm:h-auto"}>
          <HeaderBar />
        </div>
        <div className={"flex-grow md:flex-grow-0"}>
          <HomeHero />
        </div>
        <div className={"hidden sm:block flex-1"} />
      </div>
      <HomeServices />
      <HomeGallery />
      <HomeFaq />
      <HomeContact />
    </Layout>
  )
}
