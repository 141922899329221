import * as React from "react"
import Container from "../container"
import Heading from "../heading"
import ContactForm from "../contact-form"
import useSiteMetadata from "../../hooks/use-site-metadata"

export default function HomeContact() {
  const metadata = useSiteMetadata()

  return (
    <Container
      id="contact"
      className={"px-6 py-6 md:px-12 md:my-24 flex-col items-center max-w-2xl"}
    >
      <Heading className={"mb-12"}>Request a Quote</Heading>
      <p className={"mb-12 text-lg"}>
        Please enter your details and click the send button at the bottom of the
        page and Allan will be in contact shortly.
      </p>
      <ContactForm
        action={metadata.contactFormAction}
        className={"flex flex-col w-full"}
      />
    </Container>
  )
}
