import * as React from "react"

interface HeadingProps {
  className?: string
}

export default function Heading({
  className,
  children,
}: React.PropsWithChildren<HeadingProps>) {
  return (
    <h2
      className={[
        "text-4xl tracking-wide font-heading text-green-600 uppercase mb-6",
        className,
      ].join(" ")}
    >
      {children}
    </h2>
  )
}
