import * as React from "react"
import Container from "../container"
import GalleryBlock from "../gallery-block"

export default function HomeGallery() {
  // const gapClasses = "md:px-24 md:my-24"

  return (
    <Container
      id="gallery"
      fullWidth={true}
      className={"px-0 py-0 justify-between items-center"}
    >
      <GalleryBlock />
    </Container>
  )
}
