import * as React from "react"
import Container from "../container"
import {
  GiFloorPolisher,
  GiWoodBeam,
  GiChecklist,
  GiWoodenPier,
} from "react-icons/gi"

export default function HomeServices() {
  return (
    <div id="services" className={"bg-green-300"}>
      <Container
        className={
          "p-12 justify-between gap-8 items-center flex-col md:flex-row"
        }
      >
        <div className={"flex flex-col items-center"}>
          <div className={"bg-green-600 text-white p-6 rounded-full mb-6"}>
            <GiFloorPolisher size={50} />
          </div>
          <h4 className={"font-bold text-lg uppercase"}>
            Floor Sanding and Polishing
          </h4>
        </div>
        <div className={"flex flex-col items-center"}>
          <div className={"bg-green-600 text-white p-6 rounded-full mb-6"}>
            <GiChecklist size={50} />
          </div>
          <h4 className={"font-bold text-lg uppercase"}>Floor Preparation</h4>
        </div>
        <div className={"flex flex-col items-center"}>
          <div className={"bg-green-600 text-white p-6 rounded-full mb-6"}>
            <GiWoodBeam size={50} />
          </div>
          <h4 className={"font-bold text-lg uppercase"}>
            Wood Floor Maintenance
          </h4>
        </div>
        <div className={"flex flex-col items-center"}>
          <div className={"bg-green-600 text-white p-6 rounded-full mb-6"}>
            <GiWoodenPier size={50} />
          </div>
          <h4 className={"font-bold text-lg uppercase"}>Decking</h4>
        </div>
      </Container>
    </div>
  )
}
