import * as React from "react";

type ContainerProps = {
  id?: string;
  className?: string;
  fullWidth?: boolean;
  breakpoint?: "" | "xs" | "sm" | "md" | "lg" | "xl";
};

export default function Container({
  id,
  className,
  fullWidth = false,
  breakpoint = "",
  children,
}: React.PropsWithChildren<ContainerProps>) {
  const containerClass = {
    "": "container",
    xs: "xs:container",
    sm: "sm:container",
    md: "md:container",
    lg: "lg:container",
    xl: "xl:container",
  };

  const classNames = [
    fullWidth ? "max-w-full" : containerClass[breakpoint],
    "mx-auto",
    "flex",
    "px-4",
    ...[className],
  ];

  return (
    <div id={id} className={classNames.join(" ")}>
      {children}
    </div>
  );
}
