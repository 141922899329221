import * as React from "react"
import useInstagramPosts from "../../hooks/use-instagram-posts"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SRLWrapper } from "simple-react-lightbox"

type GalleryBlockProps = {
  className?: string
}

function GalleryBlock({ className }: GalleryBlockProps) {
  const posts = useInstagramPosts() || []

  return (
    <SRLWrapper>
      <div className={`flex flex-wrap ${className}`}>
        {posts.map(function (post, i) {
          return (
            <div
              key={post.node.id}
              className={`w-1/2 md:w-1/5 ${i > 5 ? "hidden md:block" : ""}`}
            >
              <a href={getImage(post.node.localFile)?.images.fallback?.src}>
                <GatsbyImage
                  alt={post.node.caption}
                  image={getImage(post.node.localFile)!}
                />
              </a>
            </div>
          )
        })}
      </div>
    </SRLWrapper>
  )
}

export default GalleryBlock
