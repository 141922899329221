import * as React from "react"
import { Link } from "gatsby"

interface NavBarLink {
  name: string
  href: string
}

export const NavBarLinks: NavBarLink[] = [
  {
    name: "Services",
    href: "#services",
  },
  {
    name: "Gallery",
    href: "#gallery",
  },
  {
    name: "FAQ",
    href: "#faq",
  },
  {
    name: "Contact",
    href: "#contact",
  },
]

export default function NavBar() {
  const navBarClass = (path: string) => {
    const currentPath =
      typeof window !== "undefined" ? window.location.pathname : ""

    return path === currentPath ? "text-orange px-2" : ""
  }

  return (
    <ul className={"gap-8 hidden lg:flex"}>
      {NavBarLinks.map((link, index) => {
        const linkClass = navBarClass(link.href)

        return (
          <li
            className={
              "uppercase transition-colors font-semibold hover:text-orange text-black"
            }
            key={`navbar-${index}`}
          >
            <Link className={linkClass} to={link.href}>
              {link.name}
            </Link>
          </li>
        )
      })}
      <li
        className={
          "uppercase transition-colors font-semibold hover:text-orange text-black"
        }
      >
        <Link
          className="bg-orange py-2 px-4 text-white hover:bg-green-600 transition-colors rounded-2xl"
          to={"#contact"}
        >
          Get a Quote
        </Link>
      </li>
    </ul>
  )
}
