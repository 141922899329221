import * as React from "react"
import Container from "../container"
import logo from "../../images/logo.svg"
import { FaFacebook, FaInstagram } from "react-icons/fa"
import SocialIcon from "../social-icon"
import useSiteMetadata from "../../hooks/use-site-metadata"

export default function Footer() {
  const metadata = useSiteMetadata()

  return (
    <div>
      <footer
        className={
          "font-cursive text-yellow text-sm flex bg-green-300 items-center w-full p-12"
        }
      >
        <Container
          className={"gap-6 md:gap-12 justify-between flex-col md:flex-row"}
        >
          <div>
            <a href={"/"}>
              <img width={250} alt="Logo" src={logo} />
            </a>
          </div>
          <div>
            <h3 className={"font-bold mb-4 text-lg uppercase"}>Machinery</h3>
            <ul className={"text-lg"}>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={"https://www.lagler.com.au/contractors/hummel.htm"}
                >
                  Lagler Hummel
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={"https://www.lagler.com.au/contractors/unico.htm"}
                >
                  Lagler Unico
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={"https://www.lagler.com.au/contractors/unico.htm"}
                >
                  Lagler Polivac SV30 High Speed Sander
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={"https://www.canterburypolishers.com.au/"}
                >
                  Canterbury Sander / Polisher
                </a>
              </li>
              <li>
                <a
                  className={"font-lg"}
                  href={"https://americansanders.com.au/product/b2-edger/"}
                >
                  B2+ Edger
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className={"font-bold mb-4 text-lg uppercase"}>Coatings</h3>
            <ul className={"text-lg"}>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={
                    "https://www.polycure.com.au/product-category/aquapro-water-based-floor-coatings/"
                  }
                >
                  Aquapro
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={
                    "https://www.polycure.com.au/product/durapol-1050-max-gloss/"
                  }
                >
                  Durapol
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={"https://www.spec-net.com.au/press/1209/ori_021209.htm"}
                >
                  Toby Aquamax
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={
                    "https://www.intergrain.com.au/professional-floor-finishes/top-coats/intergrain-enviropro-endure-1-pack/"
                  }
                >
                  Intergrain Enviropro Endure
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={
                    "https://www.feastwatson.com.au/products/indoor-products/floor-finish/floorseal-oil/"
                  }
                >
                  Floorseal Oil
                </a>
              </li>
              <li>
                <a
                  className={"font-lg hover:underline"}
                  href={
                    "https://www.feastwatson.com.au/products/indoor-products/timber-stain/prooftint/"
                  }
                >
                  Stains
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className={"font-bold mb-4 text-lg uppercase"}>Address</h3>
            <span className={"text-lg mb-4 block"}>
              P.O.Box 7253 Redland Bay 4165 QLD
            </span>
            <h3 className={"font-bold mb-4 text-lg uppercase"}>Availability</h3>
            <span className={"text-lg"}>7:30AM - 5:30PM</span>
          </div>
          <div>
            <div>
              <h3 className={"font-bold mb-4 text-lg uppercase"}>Call</h3>
              <a
                href={`tel:${metadata.socialLinks.phone.replace(/\s/g, "")}`}
                className={"text-lg block mb-4 hover:underline"}
              >
                {metadata.socialLinks.phone}
              </a>
              <h3 className={"font-bold mb-4 text-lg uppercase"}>Email</h3>
              <a
                href={`mailto:${metadata.socialLinks.email}`}
                className={"text-lg block hover:underline"}
              >
                {metadata.socialLinks.email}
              </a>
            </div>
            <div className={"flex mt-4 -ml-2"}>
              <SocialIcon
                href={metadata.socialLinks.facebook}
                icon={<FaFacebook size={30} />}
                className={"hover:text-orange transition-colors"}
                title={"Visit Als Floorsanding's Facebook"}
              />
              <SocialIcon
                href={metadata.socialLinks.instagram}
                icon={<FaInstagram size={30} />}
                className={"hover:text-orange transition-colors"}
                title={"Visit Als Floorsanding's Instagram"}
              />
            </div>
          </div>
        </Container>
      </footer>
      <div className={"p-6"}>
        <Container
          className={"justify-between items-center gap-4 flex-col md:flex-row"}
        >
          <div className={"flex text-center gap-4 sm:text-left sm:gap-8"}>
            <p>
              Copyright &copy; {new Date().getFullYear()} All Rights Reserved
            </p>
          </div>
          <div className={"flex text-center gap-4 sm:text-left sm:gap-8"}>
            <p>
              Site by{" "}
              <a
                target={"_blank"}
                href={"https://mattdoesdev.com"}
                className={"hover:underline"}
                rel="noreferrer"
              >
                mattdoesdev
              </a>
            </p>
          </div>
        </Container>
      </div>
    </div>
  )
}
