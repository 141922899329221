import * as React from "react"
import { Link } from "gatsby"

interface ButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  theme: "light" | "primary" | "dark" | "secondary"
  hoverTheme: "light" | "primary" | "dark" | "secondary"
  href: string
  internal?: boolean
}

const hoverThemes = {
  light:
    "hover:bg-white focus:bg-white hover:border-back focus:border-back hover:text-black focus:text-black",
  primary:
    "hover:bg-orange focus:bg-orange hover:border-orange focus:border-orange hover:text-white focus:text-white",
  secondary:
    "hover:bg-green-600 focus:bg-green-600 hover:border-green-600 focus:border-green-600 hover:text-white focus:text-white",
  dark: "hover:bg-black focus:bg-black hover:border-black focus:border-black hover:text-white focus:text-white",
}

const themes = {
  light: "bg-white border-black",
  primary: "bg-orange border-orange text-white",
  secondary: "bg-green-600 border-green-600 text-white",
  dark: "bg-black border-black text-white",
}

const buttonClasses =
  "py-2 px-6 uppercase rounded-2xl font-semibold transition-colors border-4 inline-block"

export { buttonClasses, themes, hoverThemes }

export default function Button({
  children,
  theme,
  hoverTheme,
  href,
  className = "",
  internal = false,
  ...props
}: ButtonProps) {
  const classNames = `${buttonClasses} ${themes[theme]} ${hoverThemes[hoverTheme]} ${className}`

  return internal ? (
    <Link to={href} className={classNames}>
      {children}
    </Link>
  ) : (
    <a className={classNames} href={href} {...props}>
      {children}
    </a>
  )
}
