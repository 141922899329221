import { useStaticQuery, graphql } from "gatsby"

export interface Question {
  question: string
  answer: string
}

export interface Home {
  faq: Question[]
}

export default function useHome(): Home {
  const { pagesYaml } = useStaticQuery(
    graphql`
      query {
        pagesYaml(
          parent: {
            internal: {
              description: { regex: "/.*site/content/pages/home.*/" }
            }
          }
        ) {
          faq {
            question
            answer
          }
        }
      }
    `
  )
  return pagesYaml
}
