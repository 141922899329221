import * as React from "react"
import Container from "../container"
import Faq from "../faq"
import Heading from "../heading"

export default function HomeFaq() {
  return (
    <Container
      id="faq"
      fullWidth={true}
      className={
        "bg-gray-100 py-6 py-6 md:px-12 md:py-24 flex-col items-center"
      }
    >
      <Heading>FAQ</Heading>
      <Faq />
    </Container>
  )
}
